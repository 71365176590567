var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pdf-schema-form" },
    [
      _c("validation-observer", {
        ref: "observer",
        staticClass: "validation-observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ dirty, invalid }) {
              return [
                _vm.currentFilteredFields.length
                  ? _c(
                      "div",
                      {
                        staticClass: "pdf-schema-form-field-list",
                        attrs: { id: "pdf-schema-form-field-list" },
                      },
                      [
                        _vm.showPdfGroupLabel
                          ? _c(
                              "div",
                              [
                                _c("schema-form-field-label", {
                                  attrs: {
                                    label: _vm.pdfGroupTitle,
                                    "help-text": _vm.pdfGroupHelpText,
                                  },
                                }),
                                _c("hr", { staticClass: "mt-0" }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showSuggestion
                          ? _c(
                              "b-form-checkbox",
                              {
                                staticClass:
                                  "pb-3 hint-checkbox tab-order-schema-checkbox",
                                model: {
                                  value: _vm.checked,
                                  callback: function ($$v) {
                                    _vm.checked = $$v
                                  },
                                  expression: "checked",
                                },
                              },
                              [
                                _vm._v(
                                  "\n        " +
                                    _vm._s(_vm.suggestion?.hint) +
                                    "\n      "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.currentFilteredFields
                          ? _c(
                              "div",
                              { attrs: { id: "unsorted-field-container" } },
                              _vm._l(
                                _vm.currentFilteredFields,
                                function (field) {
                                  return _c(
                                    "div",
                                    {
                                      key: field.id,
                                      staticClass: "resource-field",
                                    },
                                    [
                                      field.meta.type === "radio"
                                        ? _c("pdf-schema-form-radio-group", {
                                            attrs: {
                                              fields:
                                                _vm.currentFieldOrGroup(field),
                                            },
                                          })
                                        : field.meta.type === "person" ||
                                          field.meta.type === "registered_agent"
                                        ? _c("pdf-schema-form-person-group", {
                                            attrs: {
                                              fields:
                                                _vm.currentFieldOrGroup(field),
                                              "contextual-jurisdiction":
                                                _vm.jurisdiction,
                                              "use-hint": _vm.useHint,
                                            },
                                            on: {
                                              "show-contact-modal": function (
                                                $event
                                              ) {
                                                return _vm.$emit(
                                                  "show-contact-modal"
                                                )
                                              },
                                            },
                                          })
                                        : field.meta.type === "object"
                                        ? _c("pdf-schema-form-object-group", {
                                            attrs: {
                                              fields:
                                                _vm.currentFieldOrGroup(field),
                                              "contextual-jurisdiction":
                                                _vm.jurisdiction,
                                              "use-hint": _vm.useHint,
                                            },
                                          })
                                        : field.meta.type === "address" &&
                                          _vm.suggestion
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "pdf-schema-form-address-group",
                                                {
                                                  attrs: {
                                                    "field-type":
                                                      field.meta.type,
                                                    fields:
                                                      _vm.currentFieldOrGroup(
                                                        field
                                                      ),
                                                    suggestion: _vm.suggestion,
                                                    validate: false,
                                                    "contextual-jurisdiction":
                                                      _vm.jurisdiction,
                                                    "use-hint": _vm.useHint,
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          )
                                        : field.meta.type === "date" &&
                                          field.data?.parts?.length
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "pdf-schema-form-date-time-group",
                                                {
                                                  attrs: {
                                                    fields:
                                                      _vm.currentFieldOrGroup(
                                                        field
                                                      ),
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          )
                                        : field.meta.type === "bank_information"
                                        ? _c(
                                            "pdf-schema-form-bank-information-group",
                                            {
                                              attrs: {
                                                fields:
                                                  _vm.currentFieldOrGroup(
                                                    field
                                                  ),
                                                "contextual-jurisdiction":
                                                  _vm.jurisdiction,
                                              },
                                            }
                                          )
                                        : field.glossary_term_name ===
                                          "Contributions"
                                        ? _c(
                                            "pdf-schema-form-contributions-group",
                                            {
                                              attrs: {
                                                fields:
                                                  _vm.currentFieldOrGroup(
                                                    field
                                                  ),
                                                "contextual-jurisdiction":
                                                  _vm.jurisdiction,
                                              },
                                            }
                                          )
                                        : _c("pdf-schema-form-field", {
                                            key: field.id,
                                            attrs: {
                                              field:
                                                _vm.currentFieldOrGroup(field),
                                              "field-value":
                                                _vm.formData[field.id],
                                              "use-hint": _vm.useHint,
                                              "use-suggestion":
                                                _vm.useSuggestion,
                                              disabled:
                                                _vm.isDisabledField(field),
                                            },
                                            on: { input: _vm.valueUpdated },
                                          }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _c("div", { attrs: { id: "sorted-field-container" } }),
                        _vm.showProductPitch
                          ? _c("product-pitch-toggle", {
                              key: _vm.currentField.id,
                              attrs: {
                                id: `toggle-${_vm.currentField.id}`,
                                field: _vm.currentField,
                                suggestion: _vm.suggestion,
                              },
                            })
                          : _vm._e(),
                        _c("hr"),
                        _vm.showMissingRequiredMessage && dirty && invalid
                          ? _c("div", { staticClass: "invalid" }, [
                              _vm._v(
                                "\n        Please complete all required fields.\n      "
                              ),
                            ])
                          : _vm.incompleteOptionalField
                          ? _c("div", { staticClass: "invalid" }, [
                              _vm._v(
                                "\n        This field is optional. Please clear or complete all the fields to continue.\n      "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c("div", [
                      _c("label", [
                        _vm._v(
                          "Review the information on the form. When you're finished, click next."
                        ),
                      ]),
                    ]),
                _c(
                  "div",
                  { staticClass: "pdf-schema-form-navigation-buttons mt-2" },
                  [
                    _c(
                      "div",
                      { staticClass: "text-align-right" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "nav-button-link",
                            attrs: {
                              disabled: _vm.preventPrevious,
                              variant: "default",
                              "aria-label": "previous button",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.previousField()
                              },
                            },
                          },
                          [_vm._v("\n          Previous\n        ")]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "nav-button-primary",
                            attrs: {
                              disabled: _vm.disabledNextOrDoneButton || invalid,
                              variant: "primary",
                              "aria-label": "next button",
                            },
                            on: { click: _vm.handleNextButton },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.nextButtonLabel) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm.showMissingRequiredFields
                  ? _c("div", { staticClass: "mt-4 missing-required-fields" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "missing-required-fields-container px-4 pt-4 pb-2",
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "invalid font-italic font-weight-bold p-0",
                            },
                            [
                              _vm._v(
                                "\n          There are required fields that are still blank:\n        "
                              ),
                            ]
                          ),
                          _c(
                            "ul",
                            _vm._l(
                              _vm.listMissingRequiredFieldTitles,
                              function (title, titleIndex) {
                                return _c("li", { key: titleIndex }, [
                                  _c(
                                    "p",
                                    { staticClass: "invalid font-italic" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s("‣ " + title) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ])
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("hr", { staticClass: "divider-line" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }