<template>
  <div class="pdf-schema-form">
    <validation-observer
      ref="observer"
      v-slot="{ dirty, invalid }"
      class="validation-observer"
    >
      <div
        v-if="currentFilteredFields.length"
        id="pdf-schema-form-field-list"
        class="pdf-schema-form-field-list"
      >
        <div v-if="showPdfGroupLabel">
          <schema-form-field-label
            :label="pdfGroupTitle"
            :help-text="pdfGroupHelpText"
          />
          <hr class="mt-0">
        </div>

        <b-form-checkbox
          v-if="showSuggestion"
          v-model="checked"
          class="pb-3 hint-checkbox tab-order-schema-checkbox"
        >
          {{ suggestion?.hint }}
        </b-form-checkbox>

        <div v-if="currentFilteredFields" id="unsorted-field-container">
          <div
            v-for="field in currentFilteredFields"
            :key="field.id"
            class="resource-field"
          >
            <pdf-schema-form-radio-group
              v-if="field.meta.type === 'radio'"
              :fields="currentFieldOrGroup(field)"
            />

            <pdf-schema-form-person-group
              v-else-if="field.meta.type === 'person' || field.meta.type === 'registered_agent'"
              :fields="currentFieldOrGroup(field)"
              :contextual-jurisdiction="jurisdiction"
              :use-hint="useHint"
              @show-contact-modal="$emit('show-contact-modal')"
            />

            <pdf-schema-form-object-group
              v-else-if="field.meta.type === 'object'"
              :fields="currentFieldOrGroup(field)"
              :contextual-jurisdiction="jurisdiction"
              :use-hint="useHint"
            />

            <div v-else-if="field.meta.type === 'address' && suggestion">
              <pdf-schema-form-address-group
                :field-type="field.meta.type"
                :fields="currentFieldOrGroup(field)"
                :suggestion="suggestion"
                :validate="false"
                :contextual-jurisdiction="jurisdiction"
                :use-hint="useHint"
              />
            </div>

            <div v-else-if="field.meta.type === 'date' && field.data?.parts?.length">
              <pdf-schema-form-date-time-group
                :fields="currentFieldOrGroup(field)"
              />
            </div>

            <pdf-schema-form-bank-information-group
              v-else-if="field.meta.type === 'bank_information'"
              :fields="currentFieldOrGroup(field)"
              :contextual-jurisdiction="jurisdiction"
            />

            <pdf-schema-form-contributions-group
              v-else-if="field.glossary_term_name === 'Contributions'"
              :fields="currentFieldOrGroup(field)"
              :contextual-jurisdiction="jurisdiction"
            />

            <!-- Validated-->
            <pdf-schema-form-field
              v-else
              :key="field.id"
              :field="currentFieldOrGroup(field)"
              :field-value="formData[field.id]"
              :use-hint="useHint"
              :use-suggestion="useSuggestion"
              :disabled="isDisabledField(field)"
              @input="valueUpdated"
            />
          </div>
        </div>

        <div id="sorted-field-container" />

        <product-pitch-toggle
          v-if="showProductPitch"
          :id="`toggle-${currentField.id}`"
          :key="currentField.id"
          :field="currentField"
          :suggestion="suggestion"
        />

        <hr>

        <div v-if="showMissingRequiredMessage && dirty && invalid" class="invalid">
          Please complete all required fields.
        </div>

        <div v-else-if="incompleteOptionalField" class="invalid">
          This field is optional. Please clear or complete all the fields to continue.
        </div>
      </div>

      <div v-else>
        <label>Review the information on the form. When you're finished, click next.</label>
      </div>

      <div class="pdf-schema-form-navigation-buttons mt-2">
        <div class="text-align-right">
          <b-button
            class="nav-button-link"
            :disabled="preventPrevious"
            variant="default"
            aria-label="previous button"
            @click="previousField()"
          >
            Previous
          </b-button>
          <b-button
            class="nav-button-primary"
            :disabled="disabledNextOrDoneButton || invalid"
            variant="primary"
            aria-label="next button"
            @click="handleNextButton"
          >
            {{ nextButtonLabel }}
          </b-button>
        </div>
      </div>

      <div
        v-if="showMissingRequiredFields"
        class="mt-4 missing-required-fields"
      >
        <div class="missing-required-fields-container px-4 pt-4 pb-2">
          <p class="invalid font-italic font-weight-bold p-0">
            There are required fields that are still blank:
          </p>
          <ul>
            <li
              v-for="(title, titleIndex) in listMissingRequiredFieldTitles"
              :key="titleIndex"
            >
              <p class="invalid font-italic">
                {{ '&#8227; ' + title }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </validation-observer>

    <hr class="divider-line">
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'PdfSchemaForm',
  provide() {
    return {
      $validator: this.$validator,
    }
  },
  components: {
    ProductPitchToggle:                     () => import('@/components/StagelineV2/shared/ProductPitchToggle'),
    PdfSchemaFormField:                     () => import('@/components/StagelineV2/schemaForm/pdf/fields/PdfSchemaFormField'),
    PdfSchemaFormAddressGroup:              () => import('@/components/StagelineV2/schemaForm/pdf/fieldGroups/PdfSchemaFormAddressGroup'),
    PdfSchemaFormBankInformationGroup:      () => import('@/components/StagelineV2/schemaForm/pdf/fieldGroups/PdfSchemaFormBankInformationGroup'),
    PdfSchemaFormDateTimeGroup:             () => import('@/components/StagelineV2/schemaForm/pdf/fieldGroups/PdfSchemaFormDateTimeGroup'),
    PdfSchemaFormContributionsGroup:        () => import('@/components/StagelineV2/schemaForm/pdf/fieldGroups/PdfSchemaFormContributionsGroup'),
    PdfSchemaFormObjectGroup:               () => import('@/components/StagelineV2/schemaForm/pdf/fieldGroups/PdfSchemaFormObjectGroup'),
    PdfSchemaFormPersonGroup:               () => import('@/components/StagelineV2/schemaForm/pdf/fieldGroups/PdfSchemaFormPersonGroup'),
    PdfSchemaFormRadioGroup:                () => import('@/components/StagelineV2/schemaForm/pdf/fieldGroups/PdfSchemaFormRadioGroup'),
    SchemaFormFieldLabel:                   () => import('@/components/SchemaForm/fields/SchemaFormFieldLabel'),
  },
  data () {
    return {
      checked: false,
      sorted: false,
      doneButtonClicked: false,
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'jurisdiction',
      'raServiceInCart',
      'stageProductSelectedType',
      'purchasedLegalDocs',
    ]),
    ...mapGetters('stagelineSchemaForm', [
      'currentAddressFields',
      'currentBankInformationFields',
      'currentButtonGroupFields',
      'currentDateTimeFields',
      'currentField',
      'currentFilteredFields',
      'currentInitialContributionsFields',
      'currentGroupSchemaFields',
      'currentObjectGroupFields',
      'currentPage',
      'currentPersonFields',
      'fieldIdPassesValidation',
      'fieldRequired',
      'formCompleted',
      'formData',
      'isDisabledField',
      'listMissingRequiredFieldTitles',
      'pdfGroupField',
      'pdfFieldsOptional',
      'raAddressReset',
      'resource',
      'resourceFields',
      'schemaTabIndex',
      'allSchemaFields',
      'mapFieldIdsToGroupedFieldsParts',
    ]),
    ...mapGetters('checkout', [
      'productTypeInCart',
      'raServiceActiveOrInCart',
      'formationProductInCart',
    ]),
    ...mapGetters('companies', [
      'activeRAServices',
    ]),

    personAddressFieldParts() {
      if (this.currentField.meta.type === 'person' && this.currentField?.glossary_term_name !== 'Filer') {
        return Object.keys(this.mapFieldIdsToGroupedFieldsParts(this.currentFieldOrGroup(this.currentField)))
      }
      return []
    },
    hidePitch() {
      if (this.currentField.meta.type === 'person' && this.currentField?.glossary_term_name !== 'Filer' && this.personAddressFieldParts.length) {
        const validAddressFields = ['line1', 'line2', 'city', 'state_province_region', 'country', 'zip_postal_code', 'county']
        return !this.personAddressFieldParts.some(addressField => validAddressFields.includes(addressField))
      }
      return false
    },
    showProductPitch() {
      return this.currentField?.meta?.suggestion?.context &&
        !this.currentField.meta.suggestion.context?.skip_hint &&
        !this.hidePitch
    },
    suggestion() {
      return this.currentField?.meta?.suggestion
    },
    showSuggestion() {
      if (this.currentField?.data?.hide_suggestion) return false

      return !!(this.suggestion?.hint && this.suggestion?.value)
    },
    useSuggestion() {
      return this.showSuggestion && this.checked
    },
    useHint() {
      return this.currentField?.glossary_term_name === 'Official' ? false :
      (this.currentField?.useHint || this.hasActiveRaServiceOrInCart())
    },
    showPdfGroupLabel() {
      return this.currentFilteredFields.length && this.pdfGroupField
    },
    pdfGroupTitle() {
      return this.currentFilteredFields[0]?.title
    },
    pdfGroupHelpText() {
      return this.currentFilteredFields[0]?.title_help_text
    },
    fieldsSortedByTabIndex() {
      return [...this.resourceFields]
        .filter(field => field.title)
        .sort((a, b) => a.tabIndex - b.tabIndex)
    },
    firstGroup() {
      return this.pdfGroupTitle === [...this.fieldsSortedByTabIndex][0].title
    },
    lastGroup() {
      return this.pdfGroupTitle === [...this.fieldsSortedByTabIndex].pop().title
    },
    currentFieldOrGroupComplete() {
      return this.isFieldOrGroupCompleted()
    },
    anyFieldsEntered() {
      for (const key in this.formData) {
        if (this.currentFilteredFields.find(field => {
          return !!this.currentFieldOrGroupArray(field).find(f => f.id === key)
        }) && this.formData[key]) return true
      }
      return false
    },
    noFieldsRequired() {
      return !!this.currentFilteredFields.find(field => {
        return !!this.currentFieldOrGroupArray(field).find(f => !this.fieldRequired(f))
      })
    },
    isOptional() {
      if (this.pdfFieldsOptional) return true

      return this.currentFieldOrGroupComplete && this.noFieldsRequired
    },
    incompleteOptionalField() {
      return this.anyFieldsEntered &&
        !this.isFieldOrGroupCompleted(true) &&
        this.isOptional
    },
    canSkip() {
      return !this.anyFieldsEntered &&
        this.isOptional
    },
    nextButtonLabel() {
      if (this.lastGroup) return 'Continue'
      return this.canSkip ? 'Skip' : 'Next'
    },
    disabledNextOrDoneButton() {
      if (this.pdfFieldsOptional && !this.incompleteOptionalField)
        return false

      const incompleteFieldOrGroup = !this.currentFieldOrGroupComplete
      return this.doneButtonClicked || this.showMissingRequiredFields || incompleteFieldOrGroup || this.incompleteOptionalField
    },
    showMissingRequiredFields() {
      if (this.pdfFieldsOptional) return false

      return this.lastGroup &&
        !this.formCompleted &&
        this.doneButtonClicked
    },
    preventPrevious() {
      return this.firstGroup && this.stageProductSelectedType === 'premium' && this.purchasedLegalDocs
    },
    showMissingRequiredMessage() {
      if (this.pdfFieldsOptional) return false

      return this.pdfGroupField
    },
  },
  watch: {
    raServiceInCart(newValue, _oldValue) {
      if (!newValue) {
        this.setRaAddressReset(false)
        this.clearFieldsBySuggestionName('registered-agent-service')
      }
    },
    formationProductInCart(newValue, _oldValue) {
      if(!newValue) {
        this.setRaAddressReset(false)
        this.clearFieldsBySuggestionName('form a company')
      }
    },
    checked(newValue, _oldValue) {
      if (newValue) {
        this.checked = newValue
      }
    },
    currentField(newValue, _oldValue) {
      if (newValue) {
        this.checked = this.formData[newValue.id] === this.currentField.meta.suggestion?.value
      }
    },
    currentFilteredFields: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue[0]?.id !== oldValue[0]?.id) this.sortFields()
      },
    },
  },
  mounted() {
    this.sortFields()
    this.initDataProperties()
  },
  methods: {
    ...mapActions('stagelineSchemaForm', [
      'setRaAddressReset',
      'setFormData',
      'updateFormData',
      'setCurrentFieldIndex',
    ]),
    currentFieldOrGroup(field) {
      switch(field.meta.type) {
        case 'radio':
          return this.currentButtonGroupFields(field)
        case 'person':
        case 'registered_agent':
          return this.currentPersonFields
        case 'address':
          return this.currentAddressFields
        case 'bank_information':
          return this.currentBankInformationFields
        case 'date':
          return this.currentDateTimeFields
        case 'contributions':
          return this.currentInitialContributionsFields
        case 'object':
          return this.currentObjectGroupFields
        default:
          return field
      }
    },
    clearCurrentFields(fields) {
      const newFormData = cloneDeep(this.formData)
      fields.forEach(f => {
        this.resourceFields.find(field => f.id === field.id).data.disabled = false
        delete newFormData[f.id]
      })
      this.setFormData(newFormData)
    },
    clearFieldsBySuggestionName(suggestionName) {
      let newFormData = cloneDeep(this.formData)
      const raField = this.resourceFields?.length ?
        this.resourceFields.filter(rf =>  rf.type === 'object' && rf?.meta?.suggestion?.context?.name === suggestionName) :
        []
      raField.forEach(f => {
        this.resourceFields.find(field => f.id === field.id).data.disabled = false
        delete newFormData[f.id]
      })
      this.setFormData(newFormData)
    },
    hasActiveRaServiceOrInCart() {
      const productKind = this.currentField?.meta?.suggestion?.context?.product_kind
      if (productKind === 'registered_agent_product') {
        return this.raServiceActiveOrInCart || this.raServiceInCart
      }
      return false
    },
    valueUpdated() {
      this.$emit('input', this.formData)
    },
    previousField() {
      this.firstGroup ? this.$emit('previousSlide') : this.$emit('previousGroup')
    },
    handleNextButton() {
      if (this.lastGroup) this.doneButtonClicked = true
      this.nextField()
    },
    async nextField() {
      await this.updateFormData()

      if (this.lastGroup) {
        if (!this.formCompleted) return
        this.$emit('completed')
      } else {
        this.$emit('nextGroup')
      }
    },
    waitForAllTabIndexesToLoad(count) {
      count = count + 1 || 1
      if (count >= 10) {
        this.sorted = true
        return
      }
      setTimeout(() => {
        const sortedFieldContainer = document.getElementById('sorted-field-container')
        const unsortedFieldContainer = document.getElementById('unsorted-field-container')
        if (!sortedFieldContainer || !unsortedFieldContainer) {
          this.waitForAllTabIndexesToLoad(count)
          return
        }
        sortedFieldContainer.innerHTML = ''
        const unsortedFields = unsortedFieldContainer.getElementsByClassName('sortable-field-container')
        const fieldArray = Array.from(unsortedFields)
        if (fieldArray.length === 0) {
          this.waitForAllTabIndexesToLoad(count)
          return
        }
        const everyTabIndexIsLoaded =
          this.currentGroupSchemaFields.every(field =>
            fieldArray.some(fieldNode =>
              fieldNode.tabIndex === this.schemaTabIndex(field.id) ||
              Array.from(fieldNode?.children)?.some(fieldNodeChild =>
                fieldNodeChild?.tabIndex === this.schemaTabIndex(field.id))
            )
          )
        if (everyTabIndexIsLoaded) this.sortFields(fieldArray)
        if (!everyTabIndexIsLoaded) this.waitForAllTabIndexesToLoad(count)
      }, 10)
    },
    sortFields(fieldArray) {
      if (this.currentFilteredFields.length === 0) return
      if (!fieldArray) {
        this.sorted = false
        this.waitForAllTabIndexesToLoad()
        return
      }
      const sortedFieldContainer = document.getElementById('sorted-field-container')

      fieldArray
        .sort((a, b) => this.topTabIndexNearElement(a) - this.topTabIndexNearElement(b))
        .forEach((item) => sortedFieldContainer.appendChild(item))
      this.sorted = true
    },
    topTabIndexNearElement(element) {
      return element.tabIndex > 99 ? element.tabIndex :
        Array.from(element?.children)?.find(child => child?.tabIndex > 99).tabIndex
    },
    initDataProperties() {
      this.checked = !!Object.keys(this.formData).find(id => this.formData[id] === this.currentField.meta.suggestion?.value)
    },
    currentFieldOrGroupArray(field) {
      return this.currentFieldOrGroup(field).length ?
        this.currentFieldOrGroup(field) :
        [this.currentFieldOrGroup(field)]
    },
    isFieldOrGroupCompleted(optional = false) {
      return this.currentFilteredFields.every(field => {
        return this.currentFieldOrGroupArray(field).every(f =>
          this.fieldRequired(f, optional) ? this.fieldIdPassesValidation(f.id) : true
        )
      })
    },
  },
}
</script>

<style scoped lang="scss">

.pdf-schema-form {
  max-width: 520px;
}

.validation-observer {
  display: flex;
  flex-direction: column;
  min-height: 30em;
  justify-content: space-between;

  .pdf-schema-form-field-list {
    display: flex;
    flex-direction: column;
    justify-self: flex-start;
  }

  .pdf-schema-form-navigation-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    .nav-button-link {
      color: $ct-ui-primary;

      &:hover {
        background: $ct-ui-primary-light;
      }
    }

    .nav-button-primary {
      background: $ct-ui-primary;
      border-color: $ct-ui-primary;

      &:hover {
        background: $ct-ui-primary-dark;
        border-color: $ct-ui-primary;
      }
    }
  }
}
.pdf-schema-form-field-list,
.missing-required-fields {
  .invalid {
    font-size: 80%;
    color: #dc3545;
  }
}

.divider-line {
  display: none;
}

.missing-required-fields {

  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 10%;
    border: .75rem solid transparent;
    border-top: none;
    border-bottom-color: #dc3545;
  }

  .missing-required-fields-container {
    border: 2px solid #dc3545;
    border-radius: 8px;

    ul {
      list-style: none;
    }
  }
}

::v-deep .resource-radio {
  .custom-radio {
    * {
      cursor: pointer;
    }
  }
}

.hint-checkbox {
  font-size: 0.9em;
}

@media only screen and (max-width: 660px) {
  .divider-line {
    display: block;
  }
}
</style>
